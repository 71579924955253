/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'M PLUS 1';
}

.animation-typo-1 {
  animation-name: typo1;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes typo1 {
  0%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(-416px,0);
  }
}

.animation-typo-2 {
  animation-name: typo2;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes typo2 {
  0%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(-448px,0);
  }
}